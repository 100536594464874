/*
 * Headroom
 *
 * Calling the Headroom.js object
 */
/* eslint-disable */
let Headroom = require('headroom.js');

function headerTopBarLayoutCorrector(headroomOffset) {
	let topBarHeight = $('.l-header__top-bar-wrapper').innerHeight();

	if ($(window).scrollTop() >= headroomOffset) {
		$('.l-header').css({ 'margin-top': 0, top: 0 });
		$('main').css({ 'padding-top': topBarHeight });
	} else {
		$('.l-header').css({ 'margin-top': topBarHeight, top: 0 });
		$('main').css({ 'padding-top': topBarHeight });
	}
}

function headRoom() {
	// Grab an element
	let header = document.querySelector('#header');
	let topBarNegativeHeight = -$('.l-header__top-bar-wrapper').innerHeight();
	let topBarHeight = $('.l-header__top-bar-wrapper').innerHeight();
	let headroomOffset = 40;

	$(window).on('scroll', function() {
		if ($(window).width() >= 992) {
			// headerTopBarLayoutCorrector(headroomOffset);
		}

		if ($(window).scrollTop() >= 0 && $(window).width() < 992) {
			$('.l-header').css({ 'margin-top': 0, top: 0 });
			$('main').css({ 'padding-top': 0 });
		}
	});

	$(window).on('resize', function() {
		if ($(window).width() >= 992) {
			// headerTopBarLayoutCorrector(headroomOffset);
		}

		if ($(window).scrollTop() >= 0 && $(window).width() < 992) {
			$('.l-header').css({ 'margin-top': 0, top: 0 });
			$('main').css({ 'padding-top': 0 });
		}
	});

	// Construct an instance of Headroom, passing the element
	let headroom = new Headroom(header, {
		offset: headroomOffset,
		tolerance: {
			up: 15,
			down: 25,
		},
		classes: {
			// when element is initialised
			initial: 'l-headroom',
			// when scrolling up
			pinned: 'l-headroom--pinned',
			// when scrolling down
			unpinned: 'l-headroom--unpinned',
			// when above offset
			top: 'l-headroom--top',
			// when below offset
			notTop: 'l-headroom--not-top',
			// when at bottom of scoll area
			bottom: 'l-headroom--bottom',
			// when not at bottom of scroll area
			notBottom: 'l-headroom--not-bottom',
		},
		onNotTop: function() {
			if ($(window).width() >= 992) {
				// $('.l-header').css({'margin-top': 0, 'top': topBarNegativeHeight});
			}
		},
		onTop: function() {
			if ($(window).width() >= 992) {
				// $('.l-header').css({'margin-top': topBarHeight, 'top': topBarNegativeHeight});
			}
		},
	});

	// Initialise
	headroom.init();

	/*$('.l-header__anchor-link').on('click', function(e) {
		e.preventDefault();

		$('html, body').animate({
			scrollTop: $($(this).attr('href')).offset().top
		}, 'slow');
	});*/
}

export default headRoom;
