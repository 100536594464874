import Swiper, { Autoplay, EffectFade } from 'swiper';

function showCTHero() {
	const ctHeroCC = 'c-hero';

	Swiper.use([Autoplay, EffectFade]);

	new Swiper(`.${ctHeroCC}__aside-img-slider-container`, {
		loop: true,
		effect: 'fade',
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		speed: 800,
		slidesPerView: 1,
		spaceBetween: 0,
	});

	if ($(`.${ctHeroCC}.c-hero--block-type-slider`).length) {
		new Swiper(`.${ctHeroCC}__slider-container`, {
			loop: true,
			effect: 'fade',
			autoplay: {
				delay: 9000,
				disableOnInteraction: false,
			},
			autoplay: true,
			speed: 600,
			slidesPerView: 1,
			spaceBetween: 0,
			autoHeight: true,
		});
	}
}

export default showCTHero;
