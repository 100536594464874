import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function showMediaActionCards() {
	Swiper.use([Autoplay, Navigation, Pagination]);

	const blockClass = 'c-ct-media-action-cards';

	new Swiper(`.${blockClass}__slider-container`, {
		loop: false,
		speed: 600,
		autoplay: false,
		slidesPerView: 1,
		spaceBetween: 26,
		navigation: {
			prevEl: `.${blockClass}__slider-btn-prev`,
			nextEl: `.${blockClass}__slider-btn-next`,
		},
		pagination: {
			el: `.${blockClass}__slider-pagination`,
			type: 'bullets',
			clickable: true,
		},
	});
}

export default showMediaActionCards;
