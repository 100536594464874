import '../../../js/vendors/fancybox/jquery.fancybox';

function showCTMediaCollage() {
	$('[data-fancybox-trigger^="fancybox-media-text"]').fancybox({
		baseClass: 'fancybox-media-collage',
		hash: false,
		arrows: false,
		hideScrollbar: true,
	});
}

export default showCTMediaCollage;
