import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function showMediaSlider() {
	Swiper.use([Autoplay, Navigation, Pagination]);

	const blockClass = 'c-ct-media-slider';

	new Swiper(`.${blockClass}__slider-container`, {
		loop: false,
		speed: 600,
		autoplay: false,
		slidesPerView: 1,
		spaceBetween: 26,
		navigation: {
			prevEl: `.${blockClass}__slider-btn-prev`,
			nextEl: `.${blockClass}__slider-btn-next`,
		},
		breakpoints: {
			544: {
				slidesPerView: 2,
			},
			840: {
				slidesPerView: 3,
				allowTouchMove: false,
				noSwiping: true,
			},
		},
	});
}

export default showMediaSlider;
