import MicroModal from 'micromodal'; // es6 module

function modalInit() {
	MicroModal.init({
		openClass: 'is-open', // [5]
		disableScroll: true, // [6]
		disableFocus: false, // [7]
		awaitOpenAnimation: false, // [8]
		awaitCloseAnimation: false, // [9]
		debugMode: true, // [10]
	});
}

export default modalInit;
