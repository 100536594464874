/**
 * Adds event listeners to cards for expanding/collapsing content and managing checkbox interactions.
 *
 * This function targets cards designated with specific classes for interactive behaviors such as
 * expanding/collapsing their content and toggling related checkboxes within a form.
 *
 * @author Dreamers of Day
 */
export default function addListenersToExpandCards() {
	// Define CSS selectors for card components for easy reference.
	const selectors = {
		cards: '.js-expanded-card',
		collapseBtn: '.js-toggle-btn',
		content: '.js-content',
		bottomContent: '.js-bottom-content',
		checkbox: '.js-checkbox',
		formBody: '.gform_body',
		cardsSummary: '.cards-summary',
	};

	// Constants for expand and collapse max height values.
	const EXPANDED_MAX_HEIGHT = '800px';
	const COLLAPSE_MAX_HEIGHT = '238px';

	// Select the container that will hold summaries of expanded cards.
	const expandedCardsSummary = document.querySelector(selectors.cardsSummary);

	// Iterate over each card to attach event listeners.
	document.querySelectorAll(selectors.cards).forEach(card => {
		let isCollapsed = true; // Initial state of the card is collapsed.

		// Retrieve custom CSS variable for expanded card height.
		const cardHeightCssVariable = getComputedStyle(
			document.documentElement,
		).getPropertyValue('--expanded-card-height');

		// Expand/collapse toggle button event listener.
		const button = card.querySelector(selectors.collapseBtn);
		if (button) {
			button.addEventListener('click', function() {
				const content = card.querySelector(selectors.content);
				content.style.maxHeight = isCollapsed
					? EXPANDED_MAX_HEIGHT
					: COLLAPSE_MAX_HEIGHT;

				// Toggle button text based on card's expanded or collapsed state.
				this.innerText = isCollapsed
					? this.getAttribute('data-text-hide')
					: this.getAttribute('data-text-show');

				// Adjust card height on toggle.
				card.style.height = isCollapsed
					? '100%'
					: cardHeightCssVariable;

				isCollapsed = !isCollapsed;

				// Toggle visibility of additional content at the bottom.
				const bottomContent = card.querySelector(
					selectors.bottomContent,
				);
				bottomContent.classList.toggle('hidden-gradient');

				// Manage the "Learn More" button visibility.
				const learnMoreButton = this.nextSibling;
				if (learnMoreButton) {
					learnMoreButton.style.opacity = isCollapsed ? 0 : 1;
					learnMoreButton.style.pointerEvents = isCollapsed
						? 'none'
						: 'auto';
					const parentWrapper = this.parentNode;
					parentWrapper.style.paddingBottom = isCollapsed
						? '30px'
						: '100px';
				}
			});
		}

		// Add summary items for each card to the expanded cards summary container.
		const cardTitle = card?.getAttribute('data-title');
		const cardPoints = card?.getAttribute('data-points');

		if (expandedCardsSummary) {
			expandedCardsSummary.appendChild(
				createSummaryItem(cardTitle, cardPoints),
			);
		}

		// Checkbox change event for dynamic interaction within the card.
		const checkbox = card.querySelector(selectors.checkbox);
		if (checkbox) {
			checkbox.addEventListener('change', function() {
				// Synchronize card's checkbox with a corresponding form checkbox.
				const formCheckbox = document.querySelector(
					`${selectors.formBody} input[value="${cardTitle}"]`,
				);
				if (formCheckbox) {
					formCheckbox.checked = this.checked;
				}

				// Toggle visibility of the card summary item based on checkbox state.
				if (expandedCardsSummary) {
					const cardSummaryItem = expandedCardsSummary.querySelector(
						`[data-summary-item-title="${cardTitle}"]`,
					);
					cardSummaryItem.classList.toggle('hidden');
				}
			});
		}

		// Ensure form checkboxes reflect changes in card checkboxes and vice versa.
		document
			.querySelectorAll(`${selectors.formBody} input[type="checkbox"]`)
			.forEach(formCheckbox => {
				formCheckbox.addEventListener('change', function() {
					const cardCheckbox = document.querySelector(
						`${selectors.cards}[data-title="${this.value}"] ${selectors.checkbox}`,
					);
					if (cardCheckbox) {
						cardCheckbox.checked = this.checked;
					}

					// Manage visibility of summary items based on checkbox changes.
					if (expandedCardsSummary) {
						const cardSummaryItem = expandedCardsSummary.querySelector(
							`[data-summary-item-title="${this.value}"]`,
						);
						if (this.checked) {
							cardSummaryItem.classList.remove('hidden');
						} else {
							cardSummaryItem.classList.add('hidden');
						}
					}
				});
			});
	});

	/**
	 * Creates a summary item for the expanded cards list.
	 *
	 * @param {string} title The title of the card.
	 * @param {string} points The points associated with the card.
	 * @returns {HTMLElement} The summary item element.
	 */
	function createSummaryItem(title, points) {
		const summaryItem = document.createElement('div');
		summaryItem.classList.add('expanded-cards-summary__item', 'hidden');
		summaryItem.setAttribute('data-summary-item-title', title);
		summaryItem.setAttribute('data-summary-item-points', points);

		// Using template literal for dynamic text content.
		summaryItem.innerText = `${title} ${
			points ? '(' + points + ')' : ''
		}`.trim();

		return summaryItem;
	}
}
