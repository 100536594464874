function showCTHeroPricing() {
	let clicker = $('.js-read-more');
	let collapser = $('.js-collapser');

	$(clicker).click(function() {
		$(clicker).toggleClass('active');
		$(collapser).toggleClass('active');
		return false;
	});
}

export default showCTHeroPricing;
