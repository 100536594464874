function accountApplication() {
	var ajax_url =
		window.location.protocol +
		'//' +
		window.location.hostname +
		'/wp-admin/admin-ajax.php';

	// Each all account application forms on the page

	$('.account-application_wrapper').each(function() {
		var form = $(this);

		// Sync account type fields value between screens

		form.find('.account-type-first input').on('change', function() {
			if (
				!form.find(
					'.account-type-second input[value="' +
						$(this).val() +
						'"]:checked',
				).length
			) {
				form.find(
					'.account-type-second input[value="' + $(this).val() + '"]',
				)
					.prop('checked', true)
					.trigger('change');
			}
		});

		form.find('.account-type-second input').on('change', function() {
			if (
				!form.find(
					'.account-type-first input[value="' +
						$(this).val() +
						'"]:checked',
				).length
			) {
				form.find(
					'.account-type-first input[value="' + $(this).val() + '"]',
				)
					.prop('checked', true)
					.trigger('change');
			}
		});

		// END Sync account type fields value between screens

		// Trigger account type redirect check by click on the "next" button

		form.on('click', '.gform_next_button', function() {
			// Check if page with "account type first" is active and field is filled

			if (
				form.find('.account-type-first').is(':visible') &&
				form.find('.account-type-first input:checked').length
			) {
				account_application_type_redirect_check(
					form.find('.account-type-first input:checked').val(),
				);
			}
		});

		// END Trigger account type redirect check by click on the "next" button
	});

	// END Each all account application forms on the page

	// Get account application type redirect options

	function account_application_type_redirect_check(redirect_type) {
		$.ajax({
			url: ajax_url,
			type: 'POST',
			dataType: 'JSON',
			data: { action: 'get_account_application_type_redirects' },

			success: function(redirects) {
				account_application_type_redirect(redirects, redirect_type);
			},

			error: function(error) {
				console.log(error);
			},
		});
	}

	// END Get account application type redirect options

	// Check if need redirect and update account application type redirect status

	function account_application_type_redirect(redirects, redirect_type) {
		$.each(redirects, function(key, redirect) {
			if (redirect.type == redirect_type) {
				var redirect_times = parseInt(redirect.times);
				var redirect_out_of = parseInt(redirect.out_of);
				var redirect_current = parseInt(redirect.current);

				// Check if need redirect

				var redirect_fire_min_index = redirect_out_of - redirect_times;
				var redirect_fire = false;

				if (redirect_current >= redirect_fire_min_index) {
					redirect_fire = true;

					$('.c-ct-form-or-embed__inner-container').addClass(
						'loading-redirect',
					);
				}

				// END Check if need redirect

				// Update current redirect status

				if (redirect_current < redirect_out_of) {
					redirects[key].current = redirect_current + 1;
				} else {
					redirects[key].current = 0;
				}

				$.ajax({
					url: ajax_url,
					type: 'POST',
					dataType: 'JSON',
					data: {
						action: 'update_account_application_type_redirects',
						redirects: redirects,
					},

					success: function(data) {
						// When redirect status was updated make redirect if it need to be fired

						if (redirect_fire) {
							window.location.href = redirect.url;
						}
					},

					error: function(error) {
						console.log(error);
					},
				});

				// END Update current redirect status

				return false;
			}
		});
	}

	// END Check if need redirect and update account application type redirect status
}

module.exports = accountApplication;
