function copyCode() {
	async function copyTargetText(text) {
		try {
			await navigator.clipboard.writeText(text);
		} catch (err) {
			console.error('Failed to copy: ', err);
		}
	}

	const promoMessage = document.querySelector('.js-promo-message');

	document.querySelector('.js-copy-code').addEventListener('click', e => {
		e.preventDefault();

		const promoCode = e.target.closest('[data-promo-code]')?.dataset
			.promoCode;
		copyTargetText(promoCode);

		promoMessage.classList.add('visible');

		setTimeout(() => {
			promoMessage.classList.remove('visible');
		}, 700);
	});
}

export default copyCode;
