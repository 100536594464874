import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function showCTSliderPromo() {
	console.log('pium pium');
	const ctSliderPromoCC = 'c-ct-promo-slider';

	Swiper.use([Autoplay, Navigation, Pagination]);

	if ($(`.${ctSliderPromoCC}__slide-item`).length <= 1) {
		$(`.${ctSliderPromoCC}__slider-wrapper`).addClass(
			`${ctSliderPromoCC}__slider-wrapper--disabled`,
		);

		$(`.${ctSliderPromoCC}__slider-btn-prev`).addClass(
			`${ctSliderPromoCC}__slider-btn-prev--disabled`,
		);
		$(`.${ctSliderPromoCC}__slider-btn-next`).addClass(
			`${ctSliderPromoCC}__slider-btn-next--disabled`,
		);
	} else {
		$(`.${ctSliderPromoCC}__slider-wrapper`).removeClass(
			`${ctSliderPromoCC}__slider-wrapper--disabled`,
		);

		$(`.${ctSliderPromoCC}__slider-btn-prev`).removeClass(
			`${ctSliderPromoCC}__slider-btn-prev--disabled`,
		);
		$(`.${ctSliderPromoCC}__slider-btn-next`).removeClass(
			`${ctSliderPromoCC}__slider-btn-next--disabled`,
		);

		new Swiper(`.${ctSliderPromoCC}__slider-container`, {
			loop: false,
			speed: 600,
			slidesPerView: 1,
			spaceBetween: 16,
			centeredSlides: true,
			centeredSlidesBounds: true,
			navigation: {
				prevEl: `.${ctSliderPromoCC}__slider-btn-prev`,
				nextEl: `.${ctSliderPromoCC}__slider-btn-next`,
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			breakpoints: {
				400: {
					slidesPerView: 1.3,
				},

				600: {
					slidesPerView: 1.6,
				},

				800: {
					slidesPerView: 2,
				},

				1100: {
					slidesPerView: 3,
				},
			},
		});
	}
}

export default showCTSliderPromo;
