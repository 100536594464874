import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function showCTBadgesSlider() {
	const blockHook = '.js-badges-slider';

	Swiper.use([Autoplay, Navigation, Pagination]);

	document.querySelectorAll(blockHook).forEach(badgeBlock => {
		const swiperElement = badgeBlock.querySelector('.swiper-container');

		const swiper = new Swiper(swiperElement, {
			observer: true,
			observeParents: true,
			loop: true,
			autoplay: {
				delay: 0,
			},
			// autoplay: false,
			speed: 3000,
			slidesPerView: 1,
			spaceBetween: 30,
			noSwiping: true,
			allowTouchMove: false,
			breakpoints: {
				340: {
					slidesPerView: 2.5,
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 50,
				},
				1024: {
					slidesPerView: 4,
					spaceBetween: 72,
					speed: 2000,
				},
			},
		});
		swiper.on('resize', function() {
			swiper.update();
		});
	});
}

export default showCTBadgesSlider;
